import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Box, IconButton, Stack, Typography} from "@mui/material";

const Section1 = () => {
    return (
        <Stack
            alignItems={"center"}
            height={"100vh"}
            justifyContent={"center"}
            overflow={"hidden"}
            position={"relative"}
            width={"100%"}
        >
            <Stack
                sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <video
                    autoPlay
                    loop
                    muted
                    playsinline
                    style={{
                        position: "absolute",
                        minHeight: "100vh",
                        minWidth: "100vw",
                        width: "auto",
                        height: "auto",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <source
                        src={
                            "https://storage4production.blob.core.windows.net/images/VideoLanding.mp4"
                        }
                        type="video/mp4"
                    />
                </video>
            </Stack>
            <Stack height={"100%"} justifyContent={"space-around"} width={"100%"} zIndex={9999}>
                <Stack
                    direction={"column"}
                    height={"100%"}
                    justifyContent={"center"}
                    paddingInline={{xs: 2, lg: 8}}
                    width={"100%"}
                >
                    <Box height={"auto"} width={{xs: "100px", md: "150px"}}>
                        <img
                            src={
                                "https://storage4production.blob.core.windows.net/images/w_black.svg"
                            }
                        />
                    </Box>
                    <Typography fontSize={{xs: "2rem", md: "4rem"}} variant="title">
                        Abordamos la gestión de proyectos con un componente de innovación
                    </Typography>
                </Stack>
                <Stack alignItems={"center"} gap={1} marginBottom={2}>
                    <Typography
                        fontSize={{xs: "1rem", md: "1.6rem", lg: "1rem"}}
                        fontWeight={700}
                        textAlign={"center"}
                        textTransform={"uppercase"}
                        variant={"text"}
                    >
                        Nuestro recorrido en la industria
                    </Typography>
                    <IconButton
                        size={"medium"}
                        onClick={() =>
                            window.scrollBy({
                                top: window.innerHeight,
                                behavior: "smooth",
                            })
                        }
                    >
                        <KeyboardArrowDownIcon sx={{height: 50, width: 50, color: "black"}} />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Section1;
