// CONTENEDOR QUE MANEJA LA LÓGICA Y ESTÉTICA COMPARTIDA DE TODAS LAS PÁGINAS DEL SITIO QUE NO SEAN LA WEB APP

import React from "react";

import GlobalLayout from "../../styles/Layout";
import AuthContainer from "../Auth/AuthContainer";

import Footer from "./components/Footer";
import Header from "./components/Header";

const WebSiteLayout = ({children}) => {
    return (
        <GlobalLayout>
            <AuthContainer>
                <Header />
                <>{children}</>
                {/* <Footer /> */}
            </AuthContainer>
        </GlobalLayout>
    );
};

export default WebSiteLayout;
