import * as React from "react";

import SEO from "../components/seo";
import {Section1, Section2, Section3} from "../components/WebSite/LandingPage/index";
// import NavigationDots from "../components/WebSite/NavigationDots";
import WebSiteLayout from "../containers/WebSite/WebSiteLayout";
//import * as styles from "../components/index.module.css";

const IndexPage = () => (
    <WebSiteLayout>
        {/* <NavigationDots sectionsArray={landingSections} /> */}
        <Section1 />
        <Section2 />
        <Section3 />
    </WebSiteLayout>
);

export default IndexPage;

export const Head = () => {
    return (
        <SEO title="Index">
            <link
                href="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/2048px-Google_Chrome_icon_%28February_2022%29.svg.png"
                rel="icon"
            />
        </SEO>
    );
};
