import React, {useContext} from "react";
import {Button, Stack} from "@mui/material";

import {isBrowser} from "../../../utils";
import AuthContext from "../../Auth/AuthProvider";

const Header = () => {
    const {loginWithRedirect} = useContext(AuthContext);

    return (
        <Stack
            backgroundColor="#00000010"
            direction="row"
            justifyContent="flex-end"
            position="fixed"
            sx={{
                /* backdropFilter: "blur(1px) saturate(100%) contrast(100%) brightness(120%)",
                boxShadow: "1px 1px 5px black", */

                zIndex: "9",
            }}
            top="0"
            width="100%"
        >
            {/* <Button
                sx={{
                    filter: "initial",
                    color: "secondary.main",
                    margin: "10px 30px 10px 0px",
                }}
                onClick={() =>
                    loginWithRedirect({
                        redirectUri: isBrowser() && window.location.origin + "/Proyectos",
                    })
                }
            >
                Iniciar sesiÓn
            </Button> */}
        </Stack>
    );
};

export default Header;
