import React from "react";
import {
    faInstagram,
    faLinkedinIn,
    faTwitter,
    faWhatsapp,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Divider, Link, Stack, Typography} from "@mui/material";

const Section3 = () => {
    return (
        <Stack
            alignItems={"center"}
            bgcolor={"primary.main"}
            direction={{xs: "column", lg: "row"}}
            gap={10}
            height={{xs: "100%", md: "100%", lg: "100vh"}}
            justifyContent={"space-between"}
            paddingBlock={{xs: 6, lg: 12}}
            paddingInline={{xs: 2, lg: 8}}
            width={"100%"}
        >
            <Stack gap={5} justifyContent={"space-between"} width={"100%"}>
                <Typography fontSize={{xs: "2rem", md: "4rem"}} variant={"title"}>
                    Desarrollamos para construir
                </Typography>
                <Typography fontSize={{xs: "1rem", md: "1.6rem", lg: "1rem"}} variant={"text"}>
                    Estamos en la búsqueda de constructoras osadas que nos acompañen en nuestro
                    proceso de desarrollo, podes sumarte de inmediato o tan solo mantenerte
                    actualizado hasta que llegue a tu etapa de interés.
                </Typography>
            </Stack>
            <Stack alignItems={"flex-end"} gap={5} justifyContent={"space-between"} width={"100%"}>
                <Box height={"auto"} width={{xs: "100px", md: "150px"}}>
                    <img
                        src={"https://storage4production.blob.core.windows.net/images/w_black.svg"}
                    />
                </Box>
                <Stack alignItems={"flex-end"} spacing={1} width={"100%"}>
                    <Link
                        href={"https://elconstructor.com/gestion-de-proyectos-360/"}
                        target={"_blank"}
                        width={"100%"}
                    >
                        <Typography
                            display={"block"}
                            fontSize={{xs: "1rem", md: "1.6rem", lg: "1rem"}}
                            sx={{
                                ":hover": {
                                    textDecoration: "underline black",
                                },
                            }}
                            textAlign={"right"}
                            variant={"text"}
                            width={"100%"}
                        >
                            Conocé como nos fue en nuestra primer prueba piloto
                        </Typography>
                    </Link>
                    <Divider sx={{borderColor: "black"}} width={"100%"} />
                    <Typography
                        fontSize={{xs: "1rem", md: "1.6rem", lg: "1rem"}}
                        textAlign={"right"}
                        variant={"text"}
                    >
                        Sumate al desarrollo e innová antes que nadie
                    </Typography>
                    <Divider sx={{borderColor: "black"}} width={"100%"} />
                    <Stack direction={"row"} height={"100%"} spacing={2}>
                        <Link
                            href=" https://www.youtube.com/@weprojectmanagement7249/featured "
                            target={"_blank"}
                            underline={"none"}
                        >
                            <FontAwesomeIcon color={"black"} fontSize={"1.4rem"} icon={faYoutube} />
                        </Link>
                        <Link
                            href="https://www.instagram.com/weprojectmanagement/"
                            target={"_blank"}
                            underline={"none"}
                        >
                            <FontAwesomeIcon
                                color={"black"}
                                fontSize={"1.4rem"}
                                icon={faInstagram}
                            />
                        </Link>
                        <Link
                            href="https://twitter.com/WeProjectOk"
                            target={"_blank"}
                            underline={"none"}
                        >
                            <FontAwesomeIcon color={"black"} fontSize={"1.4rem"} icon={faTwitter} />
                        </Link>
                        <Link href="https://wa.me/3571561600" target={"_blank"} underline={"none"}>
                            <FontAwesomeIcon
                                color={"black"}
                                fontSize={"1.4rem"}
                                icon={faWhatsapp}
                            />
                        </Link>
                        <Link
                            href="https://www.linkedin.com/company/weproject-management"
                            target={"_blank"}
                            underline={"none"}
                        >
                            <FontAwesomeIcon
                                color={"black"}
                                fontSize={"1.4rem"}
                                icon={faLinkedinIn}
                            />
                        </Link>
                    </Stack>
                    <Divider sx={{borderColor: "black"}} width={"100%"} />
                    <Typography
                        fontSize={{xs: "1rem", md: "1.6rem", lg: "1rem"}}
                        textAlign={"right"}
                        variant={"text"}
                    >
                        contacto@weproject.management
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Section3;
