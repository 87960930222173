import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import styled from "@emotion/styled";
import {ArrowForward, FormatQuote} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Grid,
    Stack,
    Step,
    StepButton,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";

import {MUIButton} from "../../MUIComponents";

const Quote = styled.p`
    color: #ffc500;
`;

const Section2 = () => {
    const {loginWithRedirect} = useAuth0();
    const grapHeight = "600px";
    const content = [
        {
            icon: "https://storage4production.blob.core.windows.net/images/01_mvp.svg",
            label: "Mínimo producto viable",
            description:
                "Desarrollamos un complemento para MS Project que permite actualizar automáticamente duraciones de cronograma y presupuesto de obra a partir de las modificaciones de alcance",
            color: "black",
        },
        {
            icon: "https://storage4production.blob.core.windows.net/images/02_piloto.svg",
            label: "Prueba piloto",
            description:
                "Implementamos nuestro MVP en un proyecto real, obtuvimos los mismos valores presupuestarios que usando el método tradicional sumado a que se actualizaba instantáneamente al cambiar el plan de trabajo o las cantidades de alcance",
            color: "black",
        },
        {
            icon: "https://storage4production.blob.core.windows.net/images/03_proyectos.svg",
            label: "Planificación de proyectos",
            description:
                "Estamos reemplazando MS Project para poder gestionar desde la web todos los proyectos y recursos de la empresa. Permitirá planificar la cartera de proyectos en base a la capacidad de obra",
            color: "primary.main",
        },
        {
            icon: "https://storage4production.blob.core.windows.net/images/04_presupuesto.svg",
            label: "Costo y presupuesto de obra",
            description:
                "Extenderemos las funcionalidades para el cálculo y simulación de costos previstos, overhead, línea base presupuestaria, curvas de certificación, análisis de costos y precios unitarios",
            color: "grey.600",
        },
        {
            icon: "https://storage4production.blob.core.windows.net/images/05_BIM.svg",
            label: "Sincronización con BIM",
            description:
                "Permitiremos la vinculación del plan de trabajos de proyecto con el modelo BIM para automatizar la actualización del cronograma, presupuesto y requerimiento de recursos en base a lós últimos cambios en el modelo",
            color: "grey.500",
        },
        {
            icon: "https://storage4production.blob.core.windows.net/images/06_actualizacion.svg",
            label: "Actualización de obra",
            description:
                "Se podrá registrar avance y realizar certificaciones de obra. En base a los mismos el sistema calculará rendimientos actuales y proyectará las variaciones de tiempo y costos previstos para finalizar",
            color: "grey.400",
        },
    ];

    const extractLiElementsFromText = (description) => {
        const colonIndex = description.indexOf(":");

        if (colonIndex !== -1) {
            const beforeColon = description.slice(0, colonIndex).trim();
            const afterColon = description.slice(colonIndex + 1).trim();

            return (
                <Stack>
                    <Typography textAling={"left"} variant={"text"}>{`${beforeColon}:`}</Typography>
                    {afterColon.split(",").map((item, i) => (
                        <Typography key={i} as={"li"} textAlign={"left"} variant={"text"}>
                            {item}
                        </Typography>
                    ))}
                </Stack>
            );
        } else {
            return (
                <Typography textAlign={"left"} variant={"text"}>
                    {description}
                </Typography>
            );
        }
    };
    const [activeStep, setActiveStep] = useState(2);

    return (
        <Stack
            alignItems={"center"}
            bgcolor={"#f5f5f5"}
            height={"100%"}
            justifyContent={"center"}
            paddingBlock={{xs: 6, lg: 12}}
            paddingInline={{xs: 6, lg: 8}}
        >
            {/*Mobile*/}
            <Stack
                alignItems={"left"}
                display={{xs: "flex", lg: "none"}}
                justifyContent={"left"}
                width={"100%"}
            >
                <Stepper nonLinear activeStep={activeStep} orientation={"vertical"}>
                    {content.map((step, index) => (
                        <Step
                            key={step.label}
                            completed={index <= 1 ? true : false}
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    color: "primary.main",
                                },
                                // "& .MuiStepIcon-root .Mui-completed": {
                                //   fill: "black",
                                //   color: "black",
                                // },
                            }}
                        >
                            <StepButton onClick={() => setActiveStep(index)}>
                                <Typography fontWeight={700} variant={"text"}>
                                    {step.label}
                                </Typography>
                            </StepButton>
                            <StepContent>{extractLiElementsFromText(step.description)}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Stack>

            {/*Desktop*/}
            <Grid
                container
                gridTemplateColumns={"repeat(6,1fr)"}
                sx={{display: {xs: "none", lg: "grid"}}}
            >
                {content.map((step, i) =>
                    i % 2 === 0 ? (
                        <Stack
                            key={i}
                            alignItems={"center"}
                            justifyContent={"center"}
                            minHeight={grapHeight}
                            position={"relative"}
                            width={"100%"}
                        >
                            <Stack
                                alignItems={"center"}
                                bgcolor={step.color}
                                height={"30px"}
                                justifyContent={"center"}
                                position={"absolute"}
                                width={"100%"}
                            >
                                {i === 2 ? (
                                    <Typography fontWeight={"bold"} variant={"text"} zIndex={999}>
                                        ACTUALIDAD
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                <Box
                                    bgcolor={"white"}
                                    borderRadius={"50%"}
                                    display={i === 2 ? "none" : ""}
                                    height={"10px"}
                                    width={"10px"}
                                    zIndex={999}
                                />
                            </Stack>
                            <Stack
                                alignItems={"center"}
                                height={"100%"}
                                justifyContent={"space-between"}
                                position={"absolute"}
                            >
                                <Stack
                                    alignItems={"left"}
                                    height={"100%"}
                                    justifyContent={"flex-start"}
                                    width={"100%"}
                                >
                                    <Typography fontWeight={700} variant={"text"}>
                                        {step.label}
                                    </Typography>
                                    {extractLiElementsFromText(step.description)}
                                </Stack>
                                <Stack
                                    alignItems={"center"}
                                    height={"100%"}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        bgcolor={step.color}
                                        height={"200px"}
                                        top={"0px"}
                                        width={"5px"}
                                    />
                                    <Box
                                        bgcolor={step.color}
                                        borderRadius={"50%"}
                                        display={"grid"}
                                        height={"70px"}
                                        p={6}
                                        sx={{placeContent: "center"}}
                                        width={"70px"}
                                    >
                                        <Avatar
                                            src={step.icon}
                                            sx={{
                                                padding: "10px",
                                                width: "70px",
                                                height: "70px",
                                                bgcolor: "transparent",
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack
                            key={i}
                            alignItems={"center"}
                            justifyContent={"center"}
                            minHeight={grapHeight}
                            position={"relative"}
                            width={"100%"}
                        >
                            <Stack
                                alignItems={"center"}
                                bgcolor={step.color}
                                height={"30px"}
                                justifyContent={"center"}
                                position={"absolute"}
                                width={"100%"}
                            >
                                <Box
                                    bgcolor={"white"}
                                    borderRadius={"50%"}
                                    height={"10px"}
                                    width={"10px"}
                                    zIndex={999}
                                />
                            </Stack>
                            <Stack
                                alignItems={"center"}
                                height={"100%"}
                                justifyContent={"space-between"}
                                position={"absolute"}
                            >
                                <Stack
                                    alignItems={"center"}
                                    height={"100%"}
                                    justifyContent={"center"}
                                >
                                    <Box
                                        bgcolor={step.color}
                                        borderRadius={"50%"}
                                        display={"grid"}
                                        height={"70px"}
                                        p={6}
                                        sx={{placeContent: "center"}}
                                        width={"70px"}
                                    >
                                        <Avatar
                                            src={step.icon}
                                            sx={{
                                                padding: "10px",
                                                width: "70px",
                                                height: "70px",
                                                bgcolor: "transparent",
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        bgcolor={step.color}
                                        height={"200px"}
                                        top={"0px"}
                                        width={"5px"}
                                    />
                                </Stack>
                                <Stack
                                    alignItems={"left"}
                                    height={"100%"}
                                    justifyContent={"flex-end"}
                                    width={"100%"}
                                >
                                    <Typography fontWeight={700} variant={"text"}>
                                        {step.label}
                                    </Typography>
                                    {extractLiElementsFromText(step.description)}
                                </Stack>
                            </Stack>
                        </Stack>
                    ),
                )}
            </Grid>
        </Stack>
    );
};

export default Section2;
