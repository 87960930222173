import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const SEO = ({title, description, pathname, children}) => {
    const {site} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `,
    );

    return (
        <>
            {/* <title>{site.siteMetadata.title}</title> */}
            <title>WeProject</title>
            {/* <meta content={seo.description} name="description" /> */}
            {/* <meta content={seo.image} name="image" /> */}
            {/* <meta content="summary_large_image" name="twitter:card" /> */}
            {/* <meta content={seo.title} name="twitter:title" /> */}
            {/* <meta content={seo.url} name="twitter:url" /> */}
            {/* <meta content={seo.description} name="twitter:description" /> */}
            {/* <meta content={seo.image} name="twitter:image" /> */}
            {/* <meta content={seo.twitterUsername} name="twitter:creator" /> */}

            {children}
        </>
    );
};

export default SEO;
