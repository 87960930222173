import React from "react";
import {LinkedIn, MailOutline} from "@mui/icons-material";
import {Link, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

const Responsive = styled("div")(({theme}) => ({
    [theme.breakpoints.up("md")]: {
        direction: "row",
    },
}));

const Footer = () => {
    return (
        <Stack
            alignItems={"center"}
            backgroundColor={"#cc0000"}
            direction={{xs: "column", sm: "column", md: "row"}}
            height={"60px"}
            justifyContent={"center"}
            spacing={8}
        >
            <Link
                color={"secondary.dark"}
                href="https://www.linkedin.com/company/weproject-management"
                sx={{
                    textDecoration: "none",
                }}
                target={"_blank"}
            >
                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                    <LinkedIn sx={{fontSize: "1.6rem"}} />
                    <Typography color={"secondary.dark"} variant="body2">
                        /weproject-management
                    </Typography>
                </Stack>
            </Link>
            <Link
                color={"secondary.dark"}
                href="mailto:contacto@weproject.management"
                sx={{
                    textDecoration: "none",
                }}
                target={"_blank"}
            >
                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                    <MailOutline sx={{fontSize: "1.6rem"}} />
                    <Typography color={"secondary.dark"} variant="body2">
                        contacto@weproject.management
                    </Typography>
                </Stack>
            </Link>
        </Stack>
    );
};

export default Footer;
